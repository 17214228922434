<template>
  <div class="card-grid">
    <ChartCard
      chartType="bar-horizontal"
      questionType="different_transportation_method"
      full
    />
    <ChartCard chartType="bar" questionType="working_remotely" />
    <ChartCard chartType="bar" questionType="video_calls" />
    <ChartCard chartType="bar" questionType="change_to_video_calls" />
    <ChartCard chartType="bar" questionType="car_pooling" />
  </div>
</template>
<script>
import ChartCard from "../chart-cards/ChartCard.vue";
export default {
  name: "change-potential",
  metaInfo() {
    return {
      title: this.$t("dashboard.nav.change_potential"),
    };
  },
  components: { ChartCard },
};
</script>
